.card.card-one {
	position: relative;
	background: none;
	border: 1px solid $white;
	.card-title {
		padding: 1.15rem 0px 0.85rem 0px;
		border-bottom: 1px solid $white;
	}
	.card-body {
		img {
			padding: 0px 15%;
		}
	}
	.fact-sheet {
		position: absolute;
		bottom: -50px;
		padding-top: 20px;
		width: 100%;
	}
}


.card.card-two {
	position: relative;
	background: none;
	border: 1px solid $primary;
	.card-title {
		border-bottom: 1px solid $white;
	}
	.card-body {
		img {
			padding: 0px 15%;
		}
	}
	.fact-sheet {
		position: absolute;
		bottom: -50px;
		padding-top: 20px;
		width: 100%;
	}
}