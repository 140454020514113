html {
	width: 100vw;
	margin: 0;
	padding: 0;
}

body {
	font-family: $font-family-base;
	background-color: $white;
	width: 100vw;
	height: 100vh;
	color: $gray-600;
	margin: 0 0 120px 0;
}

section {
  position: relative;
}

.section {
	height: 100%;
  min-height: 100%; 
}

.more-pd-bt {
	padding-bottom: 150px;
}

.icons-fact-sheet {
	margin-top: 2rem;
	img {
		opacity: 0.5;
		margin-bottom: 3rem;
	}
	i {
		margin: 3rem 0px;
		color: $gray-500;
		display: block;
	}
}

#hd-slogan {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(16, 32, 36, 0.85);
  padding: 1rem;
  z-index: 999;
}

.text-shadow {
  text-shadow: 3px 3px 4px #102024;
}

.h-20 {
  height: 16%;
}

.h-30 {
  height: 26%;
}