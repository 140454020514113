.nav-top {
	border-bottom: 1px dotted $dark;
	a {
		color: $gray-500;
		margin-left: 1.5rem;
		padding: 0px;
		transition: all 0.5s ease-in;
		&:hover {
			color: $primary;
		}
		&.disabled {
			color: $light;
			cursor: not-allowed;
		}
	}
}