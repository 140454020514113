a.btn, .btn {
	font-family: "exoregular_italic";
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; 
	white-space: normal;
	border-radius: 0px;
	color: $white;
	padding: 0.35rem 0.7rem;
	&.btn-secondary {
   	transition: all 0.25s ease;
		color: $primary;
		
		&:not([disabled]):not(.disabled).active {
			background: $primary;
		}
	}
	&.btn-primary {
		border: 1px solid $white;
   	transition: all 0.25s ease;
		&:hover {
			background: $secondary;
			color: $primary;
		}
		&:not([disabled]):not(.disabled).active {
			background: $secondary;
		}
	}
	&:focus, &.focus {
		outline: none;
		box-shadow: none;
	}
}