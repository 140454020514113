h1, h3, h4, h5 {
  font-weight: normal;
	font-family: $brand;
	color: $primary;
	&.paragraph {
		border-bottom: 1px dotted $white;
	}
}

h2 {
	font-family: 'fira_sansbook_italic';
}



span.light {
		font-family: $headings-thin;
	}

a , a:hover {
  text-decoration: none;
}

.fira-light {
  font-family: 'fira_sansthin';
}

.brand-typo {
	font-family: $brand;
}

h5.slogan{
  text-rendering: optimizeLegibility;
  margin: 0px;
  strong {
		display:block;
		position:relative;
		opacity:0;
  }
}

a {
	text-decoration: none;
	color: $link;
	&:hover {
		color: $primary;
	}
}

.coding {
	background: $white;
	border: 1px solid $primary;
	padding: 0.35rem;
	word-break: break-all;
}

code {
	color: $danger;
}

@media (max-width: 570px) { 
  h5 {
    font-size: 1rem;
  }
}