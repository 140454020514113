$primary:       					rgba(16, 32, 36, 1); //#10201f
$secondary:     					#B1D7D3;
$warning: 								#783A5B;
$light:         					#dee2e6 !default;
$link:										rgba(120, 58, 91, 1);


$font-family-sans-serif:      "fira_sansbook",  "Helvetica Neue", Arial, sans-serif;
$font-family-base:            $font-family-sans-serif;
$headings-bold:               "exobold";
$headings-thin:               "fira_sansthin";
$brand:												"fira_sansbold";

$body-color:               	 #FFFFFF !default;

