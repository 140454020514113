svg.startlogo {
	
	margin: 12% 24% 0 24%;
	height: 70%
}

svg.trees {
	width: 100%;
}

#lines, #logo-fill {
	visibility: hidden;
}

.logo-path {
	fill: none;
	stroke: $primary;
	stroke-width: 1;
}

.logo-fill {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: $secondary
}

#holder{
  position: relative;
  width: 100%;
  margin-top: 1.5rem;
  user-select: none;
}
		
#slogans{
  position: relative;
  top: 0px;
  right: 0px;
}

#backend-bg, #frontend-bg {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	background-color: $primary; 
	background-image: none; 
	background-size: cover; 
	background-repeat: no-repeat; 
	background-position: bottom center; 
	background-attachment: fixed;
  overflow-y: scroll;
}

#nbtaw {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	font-family: 'fira_sansbook_italic';
	text-align: center;
	font-size: 5.5rem;
	color: $white;
	padding-top: 10%;
	text-shadow: 3px 3px 4px #102024;
}