.form-control {
	background: none;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $secondary;
	border-radius: 0px;
	color: $primary;
	margin-bottom: 1rem;
}

.form-control.login {
	background: $primary;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $white;
	border-radius: 0px;
	color: $white;
}

.form-control.search {
	background: $white;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $primary;
	border-radius: 0px;
	color: $primary;
}

.search-icon {
	float: left;
	margin-left: -13px;
	margin-top: 10px;
	position: relative;
	z-index: 2;
	color: $secondary;
}

select.form-control:not([size]):not([multiple]) {
	height: 2rem;
	border: 1px solid $gray-400;
}

textarea.form-control {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $white;
	&:focus {
		border-bottom: 1px solid $secondary;
		background: none;
		color: $primary;
	}
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
	border-color: none;
	box-shadow: none;
	outline: 0 none;
	border-bottom: 1px solid $secondary;
	background: none;
	color: $primary;
}

input:required {
	background: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid $warning;
}

.input-group-text {
	>i {
		line-height: 1.5;
	}
}

.tooltip {
	p {
		color: $white;
		padding: 0.2rem;
	}
}

.input-group-text {
	font-size: 1rem;
	line-height: 1.5;
	color: $secondary;
	text-align: center;
	white-space: nowrap;
	background-color: transparent;
	border-top: none;
	border-left: none;
	border-right: 1px solid $primary;
	border-bottom: 1px solid $primary;
	border-radius: 0px;
	border-radius: 0;
}
