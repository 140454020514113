.modal {
	background: $primary;
	.modal-dialog {
    max-width: 100%;
    margin: 0;
		.modal-content {
			background-color: $primary;
			border: none;
			border-radius: 0px;
			.modal-header {
				color: $white;
				text-align: center;
				border-bottom: none;
				.close {
    			font-size: 2rem;
    			line-height: 1;
    			color: $white;
    			text-shadow: none;
					opacity: .85;
				}
			}
			.modal-body {
				color: $white;
			}
			.modal-footer {
				text-align: center;
				border-top: none;
			}
		}
	}
}