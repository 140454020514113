@import "_custom_variables";
// the ~ allows to reference in node_modules //
@import "~bootstrap/scss/bootstrap";


@import "_custom_main";
@import "_custom_types";
@import "_custom_nav";
@import "_custom_cards";
@import "_custom_an_svg";
@import "_custom_forms";
@import "_custom_buttons";
@import "_custom_videos";
@import "_custom_modal";